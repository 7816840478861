import React from "react";
import {
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  CircularProgress,
} from "@mui/material";
import { DateTimePicker } from "@mui/lab";

import styles from "./style.module.scss";

export default function EditDialog({jobs, open, sheet, multiselected, loading, updateProps, onSave}) {
  return (<Dialog
    open={open}
    onClose={() => updateProps({open: false})}
  >
    <DialogTitle>
      Edit timesheet{multiselected ? "" : "s"}
    </DialogTitle>
    <DialogContent>
      <div className={styles.editContainer}>
        <FormGroup fullWidth disabled={loading}>
          <InputLabel id="job-edit">Job</InputLabel>
          <Select
            id="job-edit"
            value={sheet?.Job}
            onChange={e => updateProps({sheet: {...sheet, Job: e.target.value}})}
          >
            {jobs?.map(job => <MenuItem value={job} key={job}>{job}</MenuItem>)}
          </Select>
          {!multiselected && <>
            <InputLabel id="start-edit">Clock-in time</InputLabel>
            <DateTimePicker
              id="start-edit"
              value={sheet?.StartTimestamp}
              onChange={e => updateProps({sheet: {...sheet, StartTimestamp: e}})}
              renderInput={params => <TextField {...params}/>}
            />
            <FormControlLabel label="Is Current?" control={<Checkbox 
              checked={sheet?.IsCurrent}
              onChange={e => updateProps({sheet: {...sheet, IsCurrent: e.target.checked}})}
            />}/>
            {!sheet?.IsCurrent && <>
              <InputLabel id="end-edit">Clock-out time</InputLabel>
              <DateTimePicker
                id="end-edit"
                value={sheet?.EndTimestamp}
                onChange={e => updateProps({sheet: {...sheet, EndTimestamp: e}})}
                renderInput={params => <TextField {...params}/>}
              /> 
            </>}
          </>}
        </FormGroup>
      </div>
    </DialogContent>
    <DialogActions>
      <Button onClick={onSave} disabled={loading} startIcon={loading && <CircularProgress/>}>{loading ? "Saving..." : "Save"}</Button> 
      <Button default onClick={() => updateProps({open: false})}>Cancel</Button>
    </DialogActions>
  </Dialog>);
}