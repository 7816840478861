import React from "react";
import {
  Snackbar,
} from "@mui/material";

export default function _Snackbar({message, onClose}) {
  return (<Snackbar
    open={Boolean(message)}
    autoHideDuration={3000}
    onClose={onClose}
    message={message}
  />);
}