import React from "react";
import { SpeedDial, SpeedDialAction } from "@mui/material";
import { MenuRounded, HouseRounded, InsertChartRounded, FeedRounded } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import {api} from "./api";

export default function NavWrapper(props) {
  const navigate = useNavigate();
  const location = useLocation()?.pathname;
  const locations = [
    { 
      path: "/",
      name: "Home",
      icon: <HouseRounded/>,
    },
    { 
      path: "/sheets",
      name: "Sheets",
      icon: <FeedRounded/>,
    },
    { 
      path: "/reports",
      name: "Reports",
      icon: <InsertChartRounded/>,
    },
  ];
  const apiKey = new URLSearchParams(window.location.search).get("api-key");
  if (apiKey) {
    window.localStorage.setItem("api-key", apiKey);
  }
  api.apiKey = window.localStorage.getItem("api-key");
  if (!api.apiKey) {
    window.location = "https://jothedev.com";
  }
  return (<>
    <SpeedDial 
      ariaLabel="nav menu"
      sx={{
        position: "fixed", 
        bottom: 16, 
        right: 16,
      }} 
      icon={<MenuRounded/>}
    >
      {locations.filter(l => l.path !== location).map(l => 
        <SpeedDialAction
          key={l.name}
          icon={l.icon}
          tooltipTitle={l.name}
          onClick={() => navigate(l.path)}
        >
        </SpeedDialAction>
      )}
    </SpeedDial>
    {props.children}
  </>);
}