import React from "react";
import { 
  FormControl, 
  InputLabel, 
  MenuItem, 
  Select, 
} from "@mui/material";

export default function JobPicker({jobs, job, onChange}) {
  return (<FormControl fullWidth>
    <InputLabel id="job-select">Choose Job</InputLabel>
    <Select
      id="job-select"
      label="Choose Job"
      value={job || ""}
      onChange={(e) => onChange(e.target.value)}
    >
      {jobs?.sort((a, b) => a.startsWith("[") ? -1 : a > b)?.map(job => <MenuItem key={job} value={job} id={job}>{job}</MenuItem>)}
    </Select>
  </FormControl>)
}