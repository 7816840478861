import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider } from "@mui/lab";
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import './index.scss';
import NavWrapper from './navWrapper';
import Sheets from './sheets';
import Reports from './reports';
import Home from './home';

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#e23b3b",
      light: "#ff5b42",
      dark: "#af2d2d",
    },
    secondary: {
      main: "#3a0f0f",
      light: "#3a0f0f",
      dark: "#3a0f0f",
    }
  },
  typography: {
    fontFamily: `"Azeret Mono", monospace`,
  }
});

const Wrap = (element) => <LocalizationProvider dateAdapter={AdapterDateFns}>
  <NavWrapper>{element}</NavWrapper>
</LocalizationProvider>;

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/sheets" element={Wrap(<Sheets/>)} />
          <Route path="/reports" element={Wrap(<Reports/>)} />
          <Route path="/" element={Wrap(<Home/>)} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);