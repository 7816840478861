import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
} from "@mui/material";

export default function _Dialog({dialog, onClose}) {
  return (<Dialog
    open={Boolean(dialog)}
    onClose={onClose}
  >
    <DialogTitle>
      {dialog?.title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        {dialog?.message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {dialog?.buttons?.map(button => <Button onClick={() => {
        if (button.action === "close") {
          return onClose();
        }
        if (button.action) {
          button.action();
        }
      }} disabled={Boolean(button.disabled)} color={button.color || "primary"} startIcon={button.loading && <CircularProgress/>}>{button.text}</Button>)}
    </DialogActions>
  </Dialog>);
}