import React, { useEffect, useState } from "react";
import { MobileDatePicker } from "@mui/lab";
import { CircularProgress, FormControlLabel, Paper, Radio, RadioGroup, TextField } from "@mui/material";
import moment from "moment";

import Dialog from "../dialog";
import JobPicker from "../components/job-picker"
import {api} from "../api";
const {call} = api;

export default function Reports() {
  const [dialog, setDialog] = useState();
  const [job, setJob] = useState("");
  const [jobs, setJobs] = useState([]);
  const [timePeriod, setTimePeriod] = useState({
    type: "",
    start: moment().subtract(2, "week").set({hour: 0, minute: 0, second: 0}).valueOf(),
    end: moment().subtract(1, "day").set({hour: 23, minute: 59, second: 59}).valueOf(),
  });
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState();
  const [hourlyWage, setHourlyWage] = useState("");

  const throwError = (title, err, tryAgainCall) => {
    setDialog({
      title: `Error: Failed to ${title}!`,
      message: `Server response: ${JSON.stringify(err)}`,
      buttons: [
        {
          text: "Try again",
          action: tryAgainCall,
        },
        {
          text: "Close",
          action: () => setDialog(),
        },
      ],
    });
  }
  const getJobs = async () => {
    const {err, jobs} = await api.getJobs();
    if (err?.message === "Forbidden") {
      window.location = "https://jothedev.com";
      return;
    }
    else if (err) {
      return throwError("get jobs", err, getJobs);
    }
    setJobs(jobs);
  };
  const getReport = async ({type, start, end}) => {
    console.log(`type = ${type}, start = ${start}, end = ${end}, timePeriod = ${JSON.stringify(timePeriod)}`);
    setLoading(true);
    const ranges = {
      "Past week": {
        start: moment().subtract(1, "week").set({hour: 0, minute: 0, second: 0}),
        end: moment().subtract(1, "day").set({hour: 23, minute: 59, second: 59}),
      },
      "Past month": {
        start: moment().subtract(1, "month").set({hour: 0, minute: 0, second: 0}),
        end: moment().subtract(1, "day").set({hour: 23, minute: 59, second: 59}),
      },
      "Past year": {
        start: moment().subtract(1, "year").set({hour: 0, minute: 0, second: 0}),
        end: moment().subtract(1, "day").set({hour: 23, minute: 59, second: 59}),
      },
      "All time": {
        start: moment(0),
        end: moment().subtract(1, "day").set({hour: 23, minute: 59, second: 59}),
      },
    };
    if (type !== "Custom") {
      start = ranges[type].start.toISOString();
      end = ranges[type].end.toISOString();
    }
    else {
      start = moment(start).toISOString();
      end = moment(end).toISOString();
    }
    const {err, data} = await api.call(`/hours?job=${job}&start=${start}&end=${end}`, {
      method: "GET",
    });
    if (err?.message === "Forbidden") {
      return window.location = "https://jothedev.com";
    }
    if (err) {
      return throwError("report hours", err, () => getReport({type, start, end}));
    }
    setReport(data?.workedHoursRounded);
    setLoading(false);
  };

  useEffect(() => {
    getJobs();
  }, []);

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (<>
    <h1>Reports</h1>
    <Dialog dialog={dialog} onClose={() => setDialog()}/>
    <JobPicker job={job} jobs={jobs} onChange={(job) => {
      setJob(job);
      setTimePeriod({...timePeriod, type: ""});
      setHourlyWage("");
    }}/>
    {job && <>
      <Paper elevation={12} sx={{padding: 2, marginTop: 2}}>
        <h2>Report period</h2>
        <RadioGroup value={timePeriod.type} onChange={e => {
          const tp = {...timePeriod, type: e.target.value};
          setTimePeriod(tp);
          getReport(tp);
        }}>
          {["Past week", "Past month", "Past year", "All time", "Custom"].map(i => <FormControlLabel key={i} value={i} control={<Radio/>} label={i}/>)}
        </RadioGroup>
        <div style={{display: "flex", alignItems: "center"}}>
          <MobileDatePicker
            value={timePeriod.start}
            onChange={e => {
              const tp = {...timePeriod, start: e};
              setTimePeriod(tp);
              getReport(tp);
            }}
            renderInput={params => <TextField {...params}/>}
            disabled={timePeriod.type !== "Custom" }
          />
          <span style={{margin: "0px 10px"}}> – </span>
          <MobileDatePicker
            value={timePeriod.end}
            onChange={e => {
              const tp = {...timePeriod, end: e};
              setTimePeriod(tp);
              getReport(tp);
            }}
            renderInput={params => <TextField {...params}/>}
            disabled={timePeriod.type !== "Custom" }
          />
        </div>
      </Paper>
      {(loading || report) && <Paper elevation={12} sx={{padding: 2, marginTop: 2}}>
        {loading && <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
          <CircularProgress/>
          <h2>Loading...</h2>
        </div>}
        {report && <div>
          <h1>{report} hrs</h1>
          <div style={{display: "flex", alignItems: "center"}}>
            <span style={{marginRight: "12px"}}>Calculate total: </span>
            <TextField 
              label="Hourly wage" 
              sx={{marginRight: "12px"}}
              value={hourlyWage}
              onChange={(e) => setHourlyWage(e.target.value)}
            />
            <span style={{margin: "0px 12px"}}> = </span>
            <h2 style={{display: "inline", margin: 0}}>{!Number.isNaN(Number.parseFloat(hourlyWage))
            ? currencyFormatter.format(report * Number.parseFloat(hourlyWage))
            : "$-.--"}</h2>
          </div>
        </div>}
      </Paper>}
    </>}
  </>);
}