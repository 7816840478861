import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";

import Dialog from "../dialog";
import JobPicker from "../components/job-picker";
import Snackbar from "../components/snackBar";
import {api} from "../api.js"; 
import styles from "./style.module.scss";
import { AddRounded, ChevronLeftRounded, ChevronRightRounded, DeleteRounded, EditRounded } from "@mui/icons-material";
import EditDialog from "./editDialog";

const {getJobs, call} = api;

export default class Sheets extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      start: moment().startOf("month"),
      end: moment().endOf("month").endOf("day"),
      selectedRows: [],
      editDialog: {
        open: false,
        sheet: null,
        multiselected: false,
      },
      deleteDialog: null,
    };
  }

  async componentDidMount() {
    const {err, jobs} = await getJobs();
    if (err?.message === "Forbidden") {
      return window.location = "https://jothedev.com";
    }
    this.setState({jobs});
  }

  getSheets = async (start, end, job) => {
    this.setState({
      loading: true,
    });
    const {err, data} = await call(`/timesheets?start=${start.valueOf()}&end=${end.valueOf()}&job=${job}`, {
      method: "GET",
    });
    if (err) {
      return console.log(`ERROR failed to get timesheets: `, err);
    }
    this.setState({
      loading: false,
      sheets: data?.items || [],
    });
  }

  updateSheets = async () => {
    const {editDialog, selectedRows, start, end, job} = this.state;
    let {sheet} = editDialog;
    sheet.IsCurrent = `${sheet.IsCurrent}`;
    sheet.StartTimestamp = sheet.StartTimestamp.valueOf();
    sheet.EndTimestamp = sheet.EndTimestamp.valueOf();
    this.setState({
      editDialog: {
        ...editDialog,
        loading: true,
      },
    });
    const {err} = await call(`/timesheets/${sheet.Id}`, {
      method: "PATCH",
      body: sheet,
    });
    if (err) {
      return console.log(`ERROR failed to update timesheet(s): `, err);
    }
    this.setState({
      editDialog: {
        open: false,
        sheet: null,
        loading: false,
      },
      snackMessage: `Updated timesheet${selectedRows > 1 ? "s" : ""} successfully`,
      loading: true,
    });
    this.getSheets(start, end, job);
  }

  newSheet = async () => {
    const {editDialog, start, end, job} = this.state;
    let {sheet} = editDialog;
    sheet.IsCurrent = `${sheet.IsCurrent}`;
    sheet.StartTimestamp = sheet.StartTimestamp.valueOf();
    sheet.EndTimestamp = sheet.EndTimestamp.valueOf();
    this.setState({
      editDialog: {
        ...editDialog,
        loading: true,
      },
    });
    const {err, data} = await call(`/timesheets`, {
      method: "POST",
      body: {
        start: sheet.StartTimestamp,
        end: sheet.EndTimestamp,
        job: sheet.Job,
      }
    });
    if (err) {
      return console.log(`ERROR failed to post timesheet: `, err);
    }
    this.setState({
      editDialog: {
        open: false,
        sheet: null,
        loading: false,
      },
      snackMessage: "Created timesheet successfully",
      loading: true,
    });
    this.getSheets(start, end, job);
  }

  deleteSheets = async () => {
    const {selectedRows, deleteDialog, start, end, job} = this.state;
    this.setState({
      deleteDialog: {
        ...deleteDialog,
        title: <>Deleting sheets...</>,
        buttons: deleteDialog.buttons.map(button => button.text === "Yes" ? {...button, loading: true, enabled: false} : {...button, enabled: false}),
      },
    });

    try {
      await Promise.all(selectedRows.map(sheet => call(`/timesheets/${sheet}`, {
        method: "DELETE"
      })));
      this.setState({
        snackMessage: `Sheet${selectedRows.length > 1 ? "s" : ""} deleted successfully`,
        deleteDialog: null,
        loading: true,
      });
      this.getSheets(start, end, job);
    }
    catch (err) {
      console.log(`ERROR failed to delete sheet(s): `, err);
    }
  }

  render() {
    const {jobs, job, loading, sheets, start, end, selectedRows, editDialog, snackMessage, deleteDialog} = this.state;
    return (
      <>
        <h1>View Sheets</h1>
        <Snackbar message={snackMessage} onClose={() => this.setState({snackMessage: null})}/>
        <EditDialog
          jobs={jobs}
          open={editDialog.open}
          sheet={editDialog.sheet}
          loading={editDialog.loading} 
          updateProps={newProps => this.setState({editDialog: Object.assign(editDialog, newProps)})} onSave={editDialog.newItemMode ? this.newSheet : this.updateSheets}
        />
        <Dialog dialog={deleteDialog} onClose={() => this.setState({deleteDialog: null})}/>
        <JobPicker job={job} jobs={jobs} onChange={(job) => {
          const start = moment().startOf("month"),
            end = moment().endOf("month").endOf("day");
          this.setState({job: job, start, end});
          this.getSheets(start, end, job);
        }}/>
        {sheets ? <div className={styles.viewContainer}>
          <div className={styles.top}>
            <div className={styles.leftRightArrows}>
              <h2>{start.format("MMMM YYYY")}</h2>
              <IconButton color="primary" onClick={() => {
                const start = this.state.start.subtract(1, "month"),
                  end = this.state.end.subtract(1, "month");
                this.setState({start, end, sheets: []});
                this.getSheets(start, end, job);
              }}>
                <ChevronLeftRounded/>
              </IconButton>
              <IconButton disabled={!(start < moment().startOf("month"))} color="primary" onClick={() => {
                const start = this.state.start.add(1, "month"),
                  end = this.state.end.add(1, "month");
                this.setState({start, end, sheets: []});
                this.getSheets(start, end, job);
              }}>
                <ChevronRightRounded/>
              </IconButton>
            </div>
            <div className={styles.spacer}></div>
            <div className={styles.actionButtons}>
              <IconButton color="primary" onClick={() => {
                this.setState({
                  editDialog: {
                    open: true,
                    sheet: {
                      Job: job,
                      IsCurrent: false,
                      StartTimestamp: moment().subtract(1, "minute").valueOf(),
                      EndTimestamp: moment().valueOf(),
                    },
                    multiselected: false,
                    newItemMode: true,
                  }
                })
              }}>
                <AddRounded/>
              </IconButton>
              <IconButton color="primary" disabled={selectedRows?.length === 0} onClick={() => {
                const sheet = sheets.filter(sheet => sheet.Id === selectedRows[0])[0];
                sheet.IsCurrent = sheet.IsCurrent === "true";
                this.setState({
                  editDialog: {
                    open: true,
                    sheet,
                    multiselected: sheets.length > 1,
                    newItemMode: false,
                  },
                })}}>
                <EditRounded/>
              </IconButton>
              <IconButton color="primary" disabled={selectedRows?.length === 0} onClick={() => this.setState({
                deleteDialog: {
                  title: `Delete sheet${selectedRows.length > 1 ? "s" : ""}?`,
                  message: `Are you sure you want to delete ${selectedRows.length > 1 ? "the selected sheets" : "this sheet"}?`,
                  buttons: [{
                    text: "Yes",
                    action: this.deleteSheets,
                  }, {
                    text: "No",
                    action: () => this.setState({deleteDialog: null}),
                  }],
                }
              })}>
                <DeleteRounded/>
              </IconButton>
            </div>
          </div>
          {loading ? <CircularProgress/> : <div className={styles.table}>
            {sheets?.length > 0 ? <DataGrid
                columns={[
                  {
                    field: "start",
                    headerName: "Clock in",
                    width: 300,
                  },
                  {
                    field: "end",
                    headerName: "Clock out",
                    width: 300,
                  },
                  {
                    field: "hrs",
                    headerName: "Hours (decimal)",
                    width: 200,
                  },
                  {
                    field: "mins",
                    headerName: "Minutes",
                    width: 100,
                  },
                  {
                    field: "secs",
                    headerName: "Seconds",
                    width: 150,
                  },
                ]}
                rows={sheets.sort((a, b) => {
                    if (a.StartTimestamp < b.StartTimestamp) return 1;
                    if (a.StartTimestamp > b.StartTimestamp) return -1;
                    return 0;
                  }).map(s => s.IsCurrent === true ? ({
                    id: s.Id,
                    start: moment(s.StartTimestamp).format("ddd, Do - h:mm a"),
                    end: `Now`,
                    hrs: "—",
                    mins: "—",
                    secs: "—",
                  }) : ({
                    id: s.Id,
                    start: moment(s.StartTimestamp).format("ddd, Do - h:mm a"),
                    end: moment(s.StartTimestamp).startOf("day").diff(moment(s.EndTimestamp).startOf("day")) === 0 ? moment(s.EndTimestamp).format("h:mm a") : moment(s.EndTimestamp).format("ddd, Do h:mm a"),
                    hrs: `${Math.floor(moment(s.EndTimestamp).diff(s.StartTimestamp, "hours", true) * 100) / 100} hrs`,
                    mins: `${moment(s.EndTimestamp).diff(s.StartTimestamp, "minutes")} min${moment(s.EndTimestamp).diff(s.StartTimestamp, "minutes") === 1 ? "" : "s"}`,
                    secs: `${moment(s.EndTimestamp).diff(s.StartTimestamp, "seconds")} sec${moment(s.EndTimestamp).diff(s.StartTimestamp, "seconds") === 1 ? "" : "s"}`,
                  }))}
                checkboxSelection
                disableColumnMenu
                onSelectionModelChange={(ids) => this.setState({selectedRows: ids})}
              /> : <h3>No results</h3>}
          </div>}
        </div> : null}
        <div className={styles.endSpacer}/>
      </>
    );
  }
}