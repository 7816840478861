import React from "react";
import { 
  Button, 
  CircularProgress, 
  FormControl, 
  InputLabel, 
  MenuItem, 
  Select, 
  IconButton,
  TextField,
} from "@mui/material";
import moment from "moment";

import Dialog from "../dialog";
import JobPicker from "../components/job-picker";
import Snackbar from "../components/snackBar";
import {api} from "../api"; 
import logo from "../PileOfSheets.png";
import styles from "./style.module.scss";
import { AddRounded, DeleteRounded } from "@mui/icons-material";

const {getJobs, call} = api;

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      job: "",
      jobs: [],
    };
  }

  componentDidMount() {
    this.getJobs();
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
    clearInterval(this.timerInterval);
  }

  getJobs = async (jobToSelectWhenDone) => {
    const {err, jobs} = await getJobs();
    if (err?.message === "Forbidden") {
      window.location = "https://jothedev.com";
      return;
    }
    else if (err) {
      return this.throwError("get jobs", err, () => this.getJobs(jobToSelectWhenDone));
    }
    this.setState(Object.assign({
      jobs,
    }, jobToSelectWhenDone ? {
      job: jobToSelectWhenDone
    } : null));

    if (jobToSelectWhenDone) {
      this.getClockInfo(jobToSelectWhenDone);
    }
  }

  getClockInfo = async (job, runSilently = false) => {
    if (!runSilently) {
      this.setState({
        clockInfo: null,
      });
    }
    clearInterval(this.refreshInterval);
    clearInterval(this.timerInterval);
    const {err, data} = await call(`/clock?job=${job}`, {
      method: "GET"
    });
    if (err) {
      console.log(`ERROR failed to get clock info: `, err);
      this.throwError("get clock info", err, () => this.getClockInfo(job, runSilently));
      return;
    }
    this.setState({
      clockInfo: data,
    });
    if (data?.clockedIn === true) {
      this.refreshInterval = setInterval(() => {
        this.getClockInfo(job, true);
      }, 1000 * 60 * 10);
      this.timerInterval = setInterval(() => {
        if (this.state.clockInfo?.secondsOnClock !== null) {
          this.setState({
            clockInfo: {
              ...this.state.clockInfo,
              secondsOnClock: this.state.clockInfo.secondsOnClock + 1,
            },
          });
        }
      }, 1000);
    }
  }

  setClockState = async (state) => {
    const {job} = this.state;
    const {err} = await call("/clock", {
      method: "POST",
      body: {
        state,
        job,
      },
    });
    if (err) {
      console.log(`ERROR failed to change clock state: `, err);
      return this.throwError("change clock state", err, () => this.setClockState(state));
    }
    this.setState({
      snackMessage: `Clocked ${state === "clock_in" ? "in" : "out"} successfully`,
    });
    this.getClockInfo(job);
  }
  
  deleteClock = async () => {
    this.setState({
      snackMessage: "Deleting timesheet...",
    });
    const {clockInfo} = this.state;
    const {err} = await call(`/timesheets/${clockInfo.id}`, {
      method: "DELETE"
    });
    if (err) {
      console.log(`ERROR failed to delete timesheet: `, err);
      this.throwError("delete timesheet", err, this.deleteClock);
      return;
    }
    this.setState({
      snackMessage: "Timesheet deleted",
    });
    this.getClockInfo(this.state.job);
  }

  throwError(title, err, tryAgainCall) {
    this.setState({
      dialog: {
        title: `Error: Failed to ${title}!`,
        message: `Server response: ${JSON.stringify(err)}`,
        buttons: [
          {
            text: "Try again",
            action: tryAgainCall,
          },
          {
            text: "Close",
            action: () => this.setState({dialog: null}),
          },
        ],
      },
    });
  }

  formatSecs(secs) {
    return moment.utc(secs * 1000).format("HH:mm:ss");
  }
  
  render() {
    const {jobs, job, clockInfo, snackMessage, dialog} = this.state;

    return (<div className={styles.container}>
      <div className={styles.top}>
        <img src={logo}/>
        <h1>Pile of Sheets</h1>
      </div>
      <Snackbar message={snackMessage} onClose={() => this.setState({snackMessage: null})}/>
      <Dialog dialog={dialog} onClose={() => this.setState({dialog: null})}/>
      <div className={styles.job}>
        {jobs?.length > 0 ? (
          <div className={styles.jobPickerContainer}>
            <JobPicker job={job} jobs={jobs} onChange={(j) => {
              this.setState({job: j});
              this.getClockInfo(j);
            }}/>
            {<>
              <IconButton color="primary" onClick={() => {
                this.setState({
                  dialog: {
                    title: "New Job",
                    message: <TextField 
                      variant="outlined" 
                      label="Job title"
                      value={this.state.dialog?.jobTitleTextBox}
                      onChange={(e) => this.setState({dialog: {
                        ...this.state.dialog,
                        jobTitleTextBox: e.target.value,
                        buttons: this.state.dialog?.buttons?.map(button => button.text === "Create" ? Object.assign(button, {disabled: e.target.value === ""}) : button),
                      }})}
                    />,
                    buttons: [
                      {
                        text: "Create",
                        action: async () => {
                          const job = this.state.dialog.jobTitleTextBox;
                          this.setState({
                            dialog: {
                              ...this.state.dialog,
                              title: <>New Job<CircularProgress/></>,
                            },
                            snackMessage: `Creating job "${job}"...`,
                          });
                          const {err} = await call("/jobs", {
                            method: "POST",
                            body: {
                              name: job,
                            },
                          });
                          if (err) {
                            console.log(`ERROR failed to create job: `, err);
                            return;
                          }
                          this.setState({
                            dialog: null,
                            jobs: [],
                            job: "",
                            clockInfo: null,
                            snackMessage: "Successfully created job",
                          });
                          this.getJobs(job);
                        },
                        disabled: true,
                      },
                      {
                        text: "Cancel",
                        action: "close",
                      },
                    ],
                  },
                });
              }}>
                <AddRounded/>
              </IconButton>
              <IconButton color="primary" disabled={!Boolean(job)} onClick={() => this.setState({dialog: {
                title: `Delete job "${job}"?`,
                message: "Are you sure you want to delete this job?",
                buttons: [
                  {
                    text: "Yes",
                    action: async () => {
                      this.setState({
                        dialog: {
                          ...this.state.dialog,
                          title: <>Delete job "{job}"?<CircularProgress/></>,
                        },
                        snackMessage: `Deleting job "${job}"...`,
                      });
                      const {err} = await call("/jobs", {
                        method: "DELETE",
                        body: {
                          name: job,
                        },
                      });
                      if (err) { 
                        console.log(`ERROR failed to delete job: `, err);
                        return;
                      }
                      this.setState({
                        dialog: null,
                        jobs: [],
                        job: "",
                        clockInfo: null,
                        snackMessage: `Successfully deleted job`,
                      });
                      this.getJobs();
                    },
                  },
                  {text: "No"}
                ]
              }})}>
                <DeleteRounded/>
              </IconButton> 
            </>}
          </div>) : (
          <CircularProgress/>
        )}
        {clockInfo ? (<div className={styles.clock}>
          <div className={styles.clockStatus}>
            <div className={styles.clockStatusDot} style={{background: clockInfo.clockedIn ? "green" : "darkgray"}}/>
            <span className={styles.clockStatus} style={{color: clockInfo.clockedIn ? "green" : "darkgray"}}>CLOCKED {clockInfo.clockedIn ? "IN" : "OUT"}</span>
          </div>
          <h2 style={{color: clockInfo.clockedIn ? "red" : "darkgray"}}>{this.formatSecs(clockInfo.secondsOnClock || 0)}</h2>
          <Button 
            variant="contained" 
            onClick={() => {
              this.setState({
                snackMessage: `Clocking ${clockInfo.clockedIn ? "out" : "in"}...`,
              });
              this.setClockState(clockInfo.clockedIn ? "clock_out" : "clock_in", true);
            }}
          >
            Clock {clockInfo.clockedIn ? "out" : "in"}
          </Button>
          {clockInfo.clockedIn ? 
            <Button 
              variant="contained"
              color="secondary"
              onClick={() => {
                this.setState({
                  dialog: {
                    title: "Delete timesheet?",
                    message: "Are you sure you want to delete this active timesheet?",
                    buttons: [
                      {
                        text: "Yes",
                        action: this.deleteClock,
                      },
                      {
                        text: "No"
                      }
                    ],
                  },
                })
              }}
            >Cancel</Button> : null}
        </div>) : job !== "" ? (
          <CircularProgress/>
        ) : null}
      </div>
    </div>);
  }
}