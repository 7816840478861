export const api = {
  apiKey: "",
  call: async (url, options, callback) => {
    options = options || {};
    options.headers = {
      "Content-Type": "application/json",
      "X-Api-Key": api.apiKey,
    };
    if (options.body instanceof Object) {
      options.body = JSON.stringify(options.body);
    }
    try {
      const data = await (await fetch("https://pileofsheets-api.jothedev.com" + url, options)).json();
      if (data?.success === true) {
        return {data};
      }
      return {err: data || "Unknown error"};
    }
    catch (err) {
      return {err};
    }
  },
  getJobs: async () => {
    const {err, data} = await api.call("/jobs", {
      method: "GET"
    });
    return {err, jobs: data?.jobs};
  },
};